import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import RefundListTable from 'components/Refund/RefundListTable';
import RefundSummaryTable from 'components/Refund/RefundSummaryTable';
import Layout from 'components/common/Layout';
import progressKo from 'images/Refund/progress_ko_2.png';
import progressCh from 'images/Refund/progress_ch_2.png';
import progressEn from 'images/Refund/progress_en_2.png';
import progressJp from 'images/Refund/progress_jp_2.png';
import arrowLeft from 'images/common/chevron_left_black.png';
import withdraw from 'images/Refund/withdraw.png';
import { CountInfo, languageAtom } from 'store/globalStateAtom';
import { calculateCount, comma } from 'utils';
import WithdrawalUrlAD from 'components/advertisement/WithdrawalUrlAD';
import BannerAD from 'components/advertisement/BannerAD';
import { LANGUAGE } from 'types/language.type';

type Props = {
  isLoading: boolean;
  emitBills: (countInfo: CountInfo) => void;
};
function Refund({ emitBills, isLoading }: Props) {
  const { t } = useTranslation();
  const locationState = useLocation().state;
  const data = locationState?.data;
  const [lang] = useAtom(languageAtom);
  const { refundInfoList, ...summaryData } =
    data || ({ refundInfoList: null } as any);
  const navigate = useNavigate();
  const [isReleasing, setIsReleasing] = useState(false);

  const goBack = () => {
    navigate('/passport-certification');
  };

  const goNext = () => {
    setIsReleasing(true);
    emitBills(calculateCount(data.totalRefund));
  };

  return (
    <Layout isSetting isLoading={isLoading}>
      <img
        src={
          lang === LANGUAGE.EN
            ? progressEn
            : lang === LANGUAGE.JA
            ? progressJp
            : lang === LANGUAGE.ZH
            ? progressCh
            : progressKo
        }
        height={90}
        width={960}
        alt='progress'
        className='w-full mb-16'
      />
      <RefundListTable refundInfoList={refundInfoList} />
      <h2 className='mt-16 mb-12 font-bold text-head-01 text-mono-80'>
        {t('collect_refund')}
      </h2>
      {summaryData && <RefundSummaryTable {...summaryData} />}
      <div className='flex justify-between'>
        <button
          onClick={goBack}
          className='flex items-center px-12 py-6 font-semibold bg-white text-subHead-01 rounded-100 text-mono-80 mt-36 shadow-default'
        >
          <img src={arrowLeft} width={36} height={36} alt='arrow_left' />
          {t('prev_step')}
        </button>
        <button
          onClick={goNext}
          className='flex items-center px-12 py-6 font-semibold text-white bg-blue-100 text-head-02 disabled:bg-blue-20 rounded-100 mt-36 shadow-default active:bg-blue-150'
        >
          <img
            src={withdraw}
            width={40}
            height={40}
            alt='withdraw'
            className='mr-2'
          />
          {summaryData && (
            <>
              {lang === LANGUAGE.KO
                ? `${comma(summaryData.totalRefund)}원 환급금 받기`
                : lang === LANGUAGE.JA
                ? `KRW ${comma(summaryData.totalRefund)} 払い戻し金を受け取る`
                : lang === LANGUAGE.ZH
                ? `${comma(summaryData.totalRefund)} KRW 接收退税额`
                : `Collect Refund ${comma(summaryData.totalRefund)} KRW`}
            </>
          )}
        </button>
      </div>
      {isReleasing ? <WithdrawalUrlAD /> : ''}
      <BannerAD />
    </Layout>
  );
}

export default Refund;
