import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from 'locale/trans.en.json';
import translationJp from 'locale/trans.jp.json';
import translationCh from 'locale/trans.ch.json';
import translationKo from 'locale/trans.ko.json';
import { LANGUAGE } from 'types/language.type';

const resources = {
  en: {
    translation: translationEn,
  },
  ja: {
    translation: translationJp,
  },
  zh: {
    translation: translationCh,
  },
  ko: {
    translation: translationKo,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE.KO,
  fallbackLng: LANGUAGE.KO,
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
