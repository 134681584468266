import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import BtnItem from 'components/LanguageSelection/BtnItem';
import Layout from 'components/common/Layout';
import logo from 'images/common/ktp_logo_blue.png';
import ch from 'images/LanguageSelection/ch.png';
import jp from 'images/LanguageSelection/jp.png';
import ko from 'images/LanguageSelection/ko.png';
import en from 'images/LanguageSelection/en.png';
import BannerAD from 'components/advertisement/BannerAD';
import { LANGUAGE } from 'types/language.type';

function LanguageSelectionPage() {
  const navigate = useNavigate();
  const timerRef = useRef<number | null>(null);

  const resetTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      navigate('/promotion');
    }, 15000);
  };

  useEffect(() => {
    resetTimer();

    const handleUserInteraction = () => resetTimer();
    window.addEventListener('click', handleUserInteraction);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      window.removeEventListener('click', handleUserInteraction);
    };
  }, [navigate]);

  return (
    <Layout isKtpLogo={false} isSetting>
      <img
        src={logo}
        height={78}
        width={316}
        alt='ktp_logo'
        className='mt-36'
      />
      <h2 className='mb-24 font-bold text-head-01 mt-28'>
        Please select the language.
      </h2>
      <div className='flex flex-col gap-14'>
        <BtnItem lang={LANGUAGE.ZH} imgUrl={ch} />
        <BtnItem lang={LANGUAGE.JA} imgUrl={jp} />
        <div className='flex gap-14'>
          <BtnItem lang={LANGUAGE.EN} imgUrl={en} />
          <BtnItem lang={LANGUAGE.KO} imgUrl={ko} />
        </div>
      </div>
      <BannerAD defaultLanguage={LANGUAGE.ZH} />
    </Layout>
  );
}

export default LanguageSelectionPage;
