import { useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import PopupAD from './PopupAD';

import defaultBanner from 'images/advertisement/WithdrawalDefaultBanner.png';

import { kioskStatusAtom, languageAtom } from 'store/globalStateAtom';
import { SupportLang } from 'types/language.type';
import { ADWithdrawalIntervalTime } from 'constants/ad.const';

type AD = { advertisementDetailId: number; imageUrl: string };

type LanguageAds = {
  [key in SupportLang]?: AD[];
};

function WithdrawalUrlAD() {
  const { t } = useTranslation();
  const kioskStatus = useAtomValue(kioskStatusAtom);
  const lang = useAtomValue(languageAtom);
  const adList = (kioskStatus?.adsUrls?.withdrawalUrl as LanguageAds) ?? {};
  const [filteredADs, setFiltereADs] = useState<AD[]>([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    setFiltereADs(adList[lang as SupportLang] ?? []);
  }, [adList, lang]);

  useEffect(() => {
    if (filteredADs.length === 0) return;

    const startRotation = () => {
      setCurrentBannerIndex(
        (prevIndex) => (prevIndex + 1) % filteredADs.length,
      );
    };

    intervalRef.current = setInterval(startRotation, ADWithdrawalIntervalTime);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [filteredADs]);

  if (filteredADs?.length === 0) {
    return (
      <div>
        <div
          className={`fixed top-0 bottom-0 left-0 right-0 z-50 w-screen bg-black bg-opacity-50`}
        >
          <div
            className={`w-screen fixed bottom-0 left-0 right-0 top-0 flex flex-col justify-end`}
          >
            <div className='flex items-center justify-center w-full h-[156px] bg-mono-100'>
              <div className='w-[68px] h-[68px] rounded-full border-[12px] border-l-blue-10 border-blue-100 animate-spin'></div>
              <p className='ml-[36px] <div class="text-center font-pretendard text-[48px] font-bold leading-[72px] tracking-[-0.96px] text-[#FDFDFD]'>
                {t('refund_wait')}
              </p>
            </div>
            <PopupAD popUpUrl={defaultBanner} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {filteredADs?.map(({ imageUrl, advertisementDetailId }, index) => {
        if (index === currentBannerIndex) {
          return (
            <div key={advertisementDetailId ?? `withdrawal_${index}`}>
              <div
                className={`fixed top-0 bottom-0 left-0 right-0 z-50 w-screen bg-black bg-opacity-50`}
              >
                <div
                  className={`w-screen fixed bottom-0 left-0 right-0 top-0 flex flex-col justify-end`}
                >
                  <div className='flex items-center justify-center w-full h-[156px] bg-mono-100'>
                    <div className='w-[68px] h-[68px] rounded-full border-[12px] border-l-blue-10 border-blue-100 animate-spin'></div>
                    <p className='ml-[36px] <div class="text-center font-pretendard text-[48px] font-bold leading-[72px] tracking-[-0.96px] text-[#FDFDFD]'>
                      {t('refund_wait')}
                    </p>
                  </div>
                  <PopupAD popUpUrl={imageUrl} adID={advertisementDetailId} />
                </div>
              </div>
            </div>
          );
        }
        return '';
      })}
    </>
  );
}

export default WithdrawalUrlAD;
