import React from 'react';
import logoWhite from 'images/common/ktp_logo_white.png';
import { QRCodeSVG } from 'qrcode.react';

function PopupAD({
  popUpUrl,
  adID,
}: {
  popUpUrl: string;
  adID?: number | null;
}) {
  const isQrPresent = typeof adID === 'number' && adID >= 0;
  return (
    <div className='bg-white'>
      <img src={popUpUrl} alt='배너광고' className='w-screen' />
      {isQrPresent ? (
        <div className='flex items-center justify-center pb-[70px] mt-[60px]'>
          <QRCodeSVG
            value={`https://www.ktaxpay.com/ad?advertisementDetailId=${adID}`}
            size={140}
          />
          <span className='ml-[40px] text-[#3A3B3E] font-pretendard text-[36px] font-bold leading-[140%] tracking-[-0.9px]'>
            Scan the QR Code
            <br />
            to check the Location
          </span>
        </div>
      ) : (
        ''
      )}
      <div className='flex items-center justify-center w-full h-[156px] bg-mono-100'>
        <img src={logoWhite} alt='' className='h-[50px] z-10' />
      </div>
    </div>
  );
}
export default PopupAD;
