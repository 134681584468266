import React from 'react';
import { useTranslation } from 'react-i18next';
import { comma } from 'utils';

type Props = {
  totalCount: string;
  totalPrice: string;
  totalRefund: string;
  totalPaymentPrice: string;
};
function RefundSummaryTable({
  totalCount,
  totalPrice,
  totalRefund,
  totalPaymentPrice,
}: Props) {
  const { t } = useTranslation();

  return (
    <table className='rounded-20 overflow-hidden w-full'>
      <thead className='bg-blue-10 table w-full'>
        <tr>
          <th className='w-[200px] max-w-[200px]'>{t('refund_count')}</th>
          <th className='w-[160px] max-w-[160px]'>{t('currency')}</th>
          <th className='w-[200px] max-w-[200px]'>{t('total_price')}</th>
          <th className='w-[200px] max-w-[200px]'>{t('total_payment')}</th>
          <th className='bg-blue-20 w-[200px] max-w-[200px]'>
            {t('total_refund')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className='odd:bg-mono-01 even:bg-mono-05 w-full table'>
          <td className='w-[200px] max-w-[200px]'>{totalCount}</td>
          <td className='text-mono-65 w-[160px] max-w-[160px]'>KRW</td>
          <td className='w-[200px] max-w-[200px]'>{comma(totalPrice)}</td>
          <td className='w-[200px] max-w-[200px]'>
            {comma(totalPaymentPrice)}
          </td>
          <td className='text-blue-100 font-semibold w-[200px] max-w-[200px]'>
            {comma(totalRefund)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default RefundSummaryTable;
