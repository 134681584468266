import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { comma } from 'utils';

type Props = {
  refundInfoList: Array<{
    name: string;
    refundIndex: number;
    createdDate: string;
    price: string;
    paymentPrice: string;
    refund: string;
  }>;
};
function RefundListTable({ refundInfoList }: Props) {
  const { t } = useTranslation();

  return (
    <table className='rounded-20 overflow-hidden w-full'>
      <thead className='bg-mono-20 table w-full'>
        <tr>
          <th className='w-[192px] max-w-[192px]'>{t('payment_date')}</th>
          <th className='w-[192px] max-w-[192px]'>{t('payment_place')}</th>
          <th className='w-[192px] max-w-[192px]'>{t('price')}</th>
          <th className='w-[192px] max-w-[192px]'>{t('payment_price')}</th>
          <th className='w-[192px] max-w-[192px]'>{t('refund_price')}</th>
        </tr>
      </thead>
      <tbody
        className={`block max-h-[420px] ${
          refundInfoList?.length < 6 ? '' : 'overflow-y-scroll'
        }`}
      >
        {refundInfoList?.map((v, i) => (
          <tr key={i} className='odd:bg-mono-01 even:bg-mono-05 w-full table'>
            <td className='w-[192px] max-w-[192px]'>
              {format(new Date(v.createdDate), 'yyyy.MM.dd')}
            </td>
            <td className='text-mono-65 w-[192px] max-w-[192px]'>{v.name}</td>
            <td className='w-[192px] max-w-[192px]'>{comma(v.price)}</td>
            <td className='w-[192px] max-w-[192px]'>{comma(v.paymentPrice)}</td>
            <td className='font-semibold w-[192px] max-w-[192px]'>
              {comma(v.refund)}
            </td>
          </tr>
        ))}
        {refundInfoList?.length < 6 &&
          Array(6 - refundInfoList?.length)
            .fill(null)
            .map((v, i) => (
              <tr
                key={i}
                className='odd:bg-mono-01 even:bg-mono-05 w-full table'
              >
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
      </tbody>
    </table>
  );
}

export default RefundListTable;
